@import '_variables.scss';

.introSection {
    display: inline-block;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;

    .introTitle {
        font-family: 'Roboto Condensed';
        font-weight: 700;
        font-size: 27px;
        line-height: 45px;
        color: var(--color-000-ccc);
    }

    /* IntroText snippet */
    .introContainer {
        font-family: 'Roboto Condensed', sans-serif;
        border-radius: 4px;
        margin-top: 20px; // spacing under the title or container if no title

        .introContent {
            color: var(--color-000-ccc);
            a {
                color: var(--color-2f72eb-4b85ee);
            }

            ol,
            ul {
                margin-left: 15px;
                margin-top: 6px;

                li::marker {
                    font-size: 14px;
                    color: var(--color-000-ccc);
                    display: inline-block; /* Needed to add space between the bullet and the text */
                }
            }
            ul {
                list-style-type: disc;
            }

            // From the api class=""
            .introHeading,
            h2 {
                font-family: Roboto Condensed;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
            }
            h3 {
                font-family: Roboto Condensed;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
            }
            h4,
            h5,
            h6 {
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 24px */
            }

            .introParagraph,
            p {
                font-family: Arial;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 18px;
                text-align: justify;
                margin-bottom: 15px;
                a {
                    color: var(--color-2f72eb-4b85ee);
                }
            }
        }
    }
}
